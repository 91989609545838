// Feature Flag Treatments
export var FEATURE_EXAMPLE_ERROR_PAGES = 'enable_example_error_pages'; // AB Experiment flags

export var AB_TEST_MEMBERSHIP = 'ab_test_growth_landing_page'; // Gated flags - used as an ON/OFF toggle (not experiments) and do not need unique MTKs

export var FEATURE_ENABLE_WAITLIST = 'enable_waitlist';
export var FEATURE_SIGN_UP_V2 = 'enable_sign_up_v2';
export var FEATURE_WEBSITE_REFRESH = 'enable_store_website_refresh';
export var FEATURE_GIFTING = 'enable_store_gifting'; // Controls whether the updated onboarding 2.2 flow is setup

export var FEATURE_ONBOARDING_2_2 = 'enable_poplar_onboarding_2_2'; // skips pay screens and navigates to poplar for preview users

export var FEATURE_PREVIEW_POPLAR = 'enable_store_preview_poplar'; // Controls for nation wide launch to show the postal validation page before signup in Japan only

export var FEATURE_SKIP_POSTAL_VALIDATION = 'enable_store_skip_japan_postal_validation'; // Controls for nation wide launch to show the newsletter in the footer Japan only

export var FEATURE_FOOTER_NEWSLETTER = 'enable_store_footer_newsletter_japan'; // Controls for sunsetting the sign up flow for Yohana in the US

export var FEATURE_REMOVAL_SIGN_UP_YOHANA_US = 'enable_poplar_removal_sign_up_yohana_us'; // Controls for 3ds

export var FEATURE_3DS = 'enable_3ds';